import * as React from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from '../components/layout'
import Dynamic from '../components/dynamic'
import Actions from '../components/actions'

import HomeHighlight from '../components/home/highlight'

const IndexPage = ({ data }) => {
	const home = data.strapi.homePage

	return (
		<Layout page={home} metadata={home.metadata}>
			<div className="container pt-80 sm:pt-120 lg:pt-0">
				{/* Headline Section */}
				<section className="py-20 lg:py-40">
					<h1 className="w-10/12">{home.headline}</h1>
				</section>

				{/* Hero Unit */}
				<section className="py-40 lg:py-80">
					<Dynamic component={home.hero} />
				</section>

				{/* Featured Projects Section */}
				<section className="py-40 lg:py-80 xl:py-100 xxl:py-120">
					<div className="grid grid-cols-1 sm:grid-cols-2 gap-80 lg:gap-100 xxl:gap-120">
						{/* Card 1 */}
						<div className="col-span-1">
							<HomeHighlight
								project={home.highlight_1.project}
								image={home.highlight_1.medium}
							/>
						</div>

						{/* Card 2 */}
						<div className="col-span-1">
							<HomeHighlight
								project={home.highlight_2.project}
								image={home.highlight_2.medium}
							/>
						</div>

						{/* Card 3 */}
						<div className="col-span-full">
							<HomeHighlight
								project={home.highlight_3.project}
								image={home.highlight_3.large}
							/>
						</div>

						{/* Card 4 */}
						<div className="col-span-1">
							<HomeHighlight
								project={home.highlight_4.project}
								image={home.highlight_4.medium}
							/>
						</div>

						{/* Card 5 */}
						<div className="col-span-1">
							<HomeHighlight
								project={home.highlight_5.project}
								image={home.highlight_5.medium}
							/>
						</div>

						<Link
							className="col-span-full mx-auto underline hover:text-blue"
							to="/portfolio"
						>
							<h2 className="h1">See all projects</h2>
						</Link>
					</div>
				</section>

				{/* About Section */}
				<section className="py-60 lg:py-80">
					<Dynamic component={home.media} />

					{/* Content for About Section */}
					<div className="pt-40 lg:pt-60 xxl:pt-80">
						<h2 className="h1">
							{home.about_headline}&nbsp;
							<Link
								className="underline hover:text-blue"
								to={home.about_link_url}
							>
								{home.about_link_text}
							</Link>
						</h2>
					</div>
				</section>

				{/* Action Cards */}
				<section className="py-40 lg:py-80 xl:py-100 xxl:py-140">
					<Actions />
				</section>
			</div>
		</Layout>
	)
}

IndexPage.propTypes = {
	data: PropTypes.shape({
		strapi: PropTypes.shape({
			homePage: PropTypes.shape({
				headline: PropTypes.string.isRequired,
				hero: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
				media: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
				metadata: PropTypes.shape({}).isRequired,
			}).isRequired,
		}).isRequired,
	}).isRequired,
}

export const query = graphql`
	{
		strapi {
			homePage {
				headline
				hero {
					__typename
					... on STRAPI_ComponentSectionSingleImage {
						id
						image {
							url
							ext
							name
							height
							width
							imageFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										width: 1290
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										formats: [AUTO, WEBP, AVIF]
									)
								}
							}
						}
					}
					... on STRAPI_ComponentSectionEmbed {
						id
						video_id
						title
						padding
					}
				}
				highlight_1 {
					id
					project {
						slug
						title
						subtitle
					}
					medium {
						url
						ext
						name
						height
						width
						imageFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									width: 585
									transformOptions: { cropFocus: CENTER, fit: COVER }
									layout: CONSTRAINED
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
				highlight_2 {
					id
					project {
						slug
						title
						subtitle
					}
					medium {
						url
						ext
						name
						height
						width
						imageFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									width: 585
									transformOptions: { cropFocus: CENTER, fit: COVER }
									layout: CONSTRAINED
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
				highlight_3 {
					id
					project {
						slug
						title
						subtitle
					}
					large {
						url
						ext
						name
						height
						width
						imageFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									width: 1290
									transformOptions: { cropFocus: CENTER, fit: COVER }
									layout: CONSTRAINED
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
				highlight_4 {
					id
					project {
						slug
						title
						subtitle
					}
					medium {
						url
						ext
						name
						height
						width
						imageFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									width: 585
									transformOptions: { cropFocus: CENTER, fit: COVER }
									layout: CONSTRAINED
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
				highlight_5 {
					id
					project {
						slug
						title
						subtitle
					}
					medium {
						url
						ext
						name
						height
						width
						imageFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									width: 585
									transformOptions: { cropFocus: CENTER, fit: COVER }
									layout: CONSTRAINED
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
				media {
					__typename
					... on STRAPI_ComponentSectionSingleImage {
						id
						image {
							url
							ext
							name
							height
							width
							imageFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										width: 1290
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										formats: [AUTO, WEBP, AVIF]
									)
								}
							}
						}
					}
					... on STRAPI_ComponentSectionEmbed {
						id
						video_id
						title
						padding
					}
				}
				about_headline
				about_link_url
				about_link_text
				footer_headline
				footer_link_url
				footer_link_text
				metadata {
					meta_title
					meta_desc
					meta_image {
						url
						imageFile {
							publicURL
						}
					}
				}
			}
		}
	}
`

export default IndexPage
