import * as React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import Image from '../partials/image'

// Highlight Card Component
const HomeHighlight = ({ project, image }) => {
	return (
		<Link className="block group" to={`/portfolio/${project.slug}`}>
			{/* Card Image */}
			<Image asset={image} />

			{/* Card Title */}
			<h3 className="pt-15 lg:pt-30 group-hover:text-blue group-hover:underline">
				{project.title}
			</h3>

			{/* Card Subtitle */}
			<h3 className="lg:pt-5 text-grey">{project.subtitle}</h3>
		</Link>
	)
}

HomeHighlight.propTypes = {
	project: PropTypes.shape({
		slug: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		subtitle: PropTypes.string.isRequired,
	}).isRequired,
	image: PropTypes.shape({}).isRequired,
}

export default HomeHighlight
